import React, { useState } from "react";
import { graphql, Link } from "gatsby"
import Image from "gatsby-plugin-sanity-image";
import Seo from "../components/SEO/SEO"
import PortableText from "../components/portableText";
import Footer from "../components/footer"

// import Swiper core and required modules
import { Pagination, A11y, Autoplay, EffectFade, Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/pagination';
import "swiper/css/effect-fade"

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      defaultImage {
        asset {
          _id
        }
      }
    }
    sanityHome {
        description
      _rawCopy
      headline
      slideshow {
        images {
          ...Image
        }
      }
    }
  }
`

const IndexPage = (props) => {

  const { data } = props
  const site = (data || {}).site
  const [open, setOpen] = useState(false);

  const HeroSlide = ({ item }) => (
    <>
      <Image
        {...item}
        width={1921}
        height={1154}
        className="swiper-lazy"
        alt=" "
      />
    </>
  );

  const pagination = {
    clickable: true 
  };

  return (
    <>
      <Seo
        title={site.title}
        description={data.sanityHome.description}
        image={site.defaultImage}
      />
        <div className="absolute w-full top-0 z-10">
        <header className="container">
          <div className="flex lg:grid lg:grid-cols-2 gap-4 pt-6 lg:pt-16 lg:pb-[5px] lg:gap-16 items-start text-white">
            <Link className="logo-home" to="/"><span className="sr-only">Dimpat</span></Link>
            <button
              className="ml-auto flex lg:hidden uppercase -mt-[6px] tracking-[0.3em]"
              onClick={() => {
                setOpen(!open);
              }}
            >Menu</button>
            <nav className="hidden lg:flex lg:ml-auto gap-4 lg:gap-8 uppercase text-[15px] tracking-[0.1em] lg:tracking-[0.3em]">
              <Link to="/projects">Projects</Link>
              <Link to="/about-us">About us</Link>
              <Link to="/get-in-touch">Get in touch</Link>
            </nav>
          </div>
        </header>
        <div className={open ? `fixed bg-[#1C6DB5] text-white top-0 left-0 w-screen h-screen lg:hidden z-20` : `fixed invisible bg-[#1C6DB5] text-white top-0 left-0 w-screen h-screen lg:hidden z-20`}>
          <div className="p-6 flex flex-col relative h-full">
            <button
                className="uppercase absolute right-4 -mt-[6px] tracking-[0.3em]"
                onClick={() => {
                  setOpen(!open);
                }}
              >Close</button>
            <ul className="mt-16 w-screen uppercase text-[25px] tracking-[0.1em] mobile">
              <li className="mb-5"><Link className="mb-5 inline-block" to="/projects">Projects</Link></li>
              <li className="mb-5"><Link className="mb-5 inline-block" to="/about-us">About us</Link></li>
              <li className="mb-5"><Link to="/get-in-touch">Get in touch</Link></li>
            </ul>
            <ul className="mt-auto flex socials gap-8 uppercase text-[15px] tracking-[0.3em]">
              <li>
                <a href="https://www.instagram.com/dimpat__/" target="_blank" rel="noreferrer">Instagram</a>
              </li>
              <li>
                <a href="https://www.instagram.com/dimpat__/" target="_blank" rel="noreferrer">Facebook</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Swiper
        className="aspect-video hero"
        modules={[Lazy, Autoplay, A11y, EffectFade, Pagination]}
        slidesPerView={1}
        pagination={pagination}
        effect={'fade'}
        loop={true}
        lazy={true}
        speed={1000}
        autoplay={{
          "delay": 5000,
          "disableOnInteraction": false
        }}
      >
        {data.sanityHome.slideshow.images.map((item, index) => (
          <SwiperSlide>
            <HeroSlide item={item} key={index} />
          </SwiperSlide>
        ))}
      </Swiper>
      <main className="container relative grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-16 mt-12 mb-24 lg:mt-32 lg:mb-60">
        {data.sanityHome.headline && (
          <div className="text-[34px] leading-[44px] mb-5 lg:mb-0 lg:text-[64px] lg:leading-[74px]">
            <h1 className="lg:pr-16">
              {data.sanityHome.headline}
            </h1>
          </div>
        )}
        {data.sanityHome._rawCopy && (
          <PortableText className="copy" blocks={data.sanityHome._rawCopy} />
        )}
      </main>
      <Footer />
    </>
  )
  
}

export default IndexPage
